/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import styled from 'styled-components'

// Components
import Layout from 'components/layout/Layout'
import {SEO, ParseContent} from 'components/shared'
import { Accordion } from 'components/elements'

const Hero = styled.section`
  background-size: 100% 400px;

  @media screen and (max-width: 991px) {
    background-size: cover !important;
  }
`

const HeroContent = styled.div`
  padding-top: 154px;
  padding-bottom: 90px;
  padding-left: 30px;

  @media screen and (max-width: 992px){
    margin-top: 10px;
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />

       <Hero
          className="hero-image-small"
          style={{
            backgroundImage: `url(${acf.banner.background.localFile.childImageSharp.fluid.src})`
          }}
        >
        <HeroContent className="container px-3">
          <div className="mt-5 pt-5 pb-4">
            <ParseContent content={acf.banner.description} />
          </div>
        </HeroContent>
      </Hero>
      
      <div className="container pb-5">
        <div className="d-flex mt-5 flex-wrap justify-content-between">
          {acf.faq.map(({ description, questions }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="col-lg-5" key={index}>
              <ParseContent className='my-3' content={description} />
              <div className="mt-5">
                <Accordion data={questions} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          background {
            localFile {
              publicURL
              childImageSharp {
                fluid(toFormat: WEBP, maxWidth: 2000, quality:100) {
                  src
                }
              }
            }
          }
        }
        faq {
          description
          questions {
            answer
            question
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate